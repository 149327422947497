import { ApiProxy } from './ApiProxy'

type APIQueryArgCollection = ApiTypes.ApiQueryArgCollection
type APIBodyArgCollection = ApiTypes.ApiBodyArgCollection

/**
 * 发送 get 请求
 * @param path 请求路径
 * @param urlArgs query参数，参数名会自动被转化成下划线命名法
 */
export function get<T = {}>(path: string, urlArgs?: APIQueryArgCollection): Promise<T> {
  return new ApiProxy().sendRequest('GET', path, urlArgs)
}

/**
 * 发送 post 请求
 * @param path 请求路径
 * @param urlArgs query参数，参数名会自动被转化成下划线命名法
 * @param bodyArgs body参数，参数名会自动被转化成下划线命名法
 */
export function post<T = {}>(
  path: string,
  urlArgs?: APIQueryArgCollection,
  bodyArgs?: APIBodyArgCollection
): Promise<T> {
  return new ApiProxy().sendRequest('POST', path, urlArgs, bodyArgs)
}