import { storageHelper } from '@/helpers/storageHelper'
import { userService } from '@/services/userService'
import { MessageInstance } from 'antd/es/message'
import { observable } from 'mobx'

class UiController {
  public message!: MessageInstance

  @observable showFreeVipNoCountDialog = false
  @observable showVipNoCountDialog = false
  @observable showContactDialog = false
  @observable showAddCountDialog = false
  @observable showLowVersionDialog = false
  @observable showUpgradeDialog = false

  public init() {
    // do nothing
  }

  public getInputDataFromCache(templateId: string): PostData.AiTaskInputData | null {
    const inputDataMap = this.getCachedInputDataMap()
    return inputDataMap[templateId]
  }

  public setInputDataToCache(templateId: string, inputData: PostData.AiTaskInputData | null) {
    const inputDataMap = this.getCachedInputDataMap()
    inputDataMap[templateId] = inputData
    storageHelper.set({ inputDataMap })
  }

  public tryClearCachedInputData() {
    const canClearInputDataMap =
      storageHelper.get(['canClearInputDataMap']).canClearInputDataMap || {}
    const inputDataMap = this.getCachedInputDataMap()
    Object.keys(canClearInputDataMap).forEach((templateId) => {
      if (canClearInputDataMap[templateId]) {
        inputDataMap[templateId] = null
        canClearInputDataMap[templateId] = null
      }
    })
    storageHelper.set({ inputDataMap, canClearInputDataMap })
  }

  public setCanClearInputData(templateId: string) {
    const canClearInputDataMap =
      storageHelper.get(['canClearInputDataMap']).canClearInputDataMap || {}
    canClearInputDataMap[templateId] = 1
    storageHelper.set({ canClearInputDataMap })
  }

  public showNoCountDialog() {
    if (userService.isVip) {
      this.showVipNoCountDialog = true
    } else {
      this.showFreeVipNoCountDialog = true
    }
  }

  private getCachedInputDataMap() {
    return storageHelper.get(['inputDataMap']).inputDataMap || {}
  }
}

export const uiController = new UiController()
