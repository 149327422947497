export const PAGE_LINKS = {
  intro: '/',
  home: '/home',
  login: '/login',
  personal: '/personal',
  form: '/form',
  result: '/result',
  help: '/help',
  chat: '/chat',
}
// 为了和 web 项目共用同一个域名，需要将 extension 项目挂载在 /addins 下
// Object.keys(PAGE_LINKS).forEach((key) => {
//   ;(PAGE_LINKS as any)[key] = `/addins${(PAGE_LINKS as any)[key]}`
// })
export enum ERROR_CODE {
  NO_COUNT = 'no_count',
}
export const CONTACT_QR_CODE =
  'https://assets.weibanzhushou.com/web/pt-web/huixie_contactQrCode.8a37b3dbee44.png'
