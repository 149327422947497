import { AigcServiceConsts } from '@/services/aigcService/consts'
import { userService } from '@/services/userService'
import { officeHelper } from './officeHelper'

class OfficeCommand {
  private initialized = false

  public init() {
    if (this.initialized) {
      return
    }
    this.registerSelectionChangeLister()
    this.registerCustomTabFunction()
    this.initialized = true
  }

  private registerSelectionChangeLister() {
    Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, async () => {
      this.checkSelectedText()
    })
    this.checkSelectedText()
  }

  private registerCustomTabFunction() {
    const insertStyleTemplate = async (event: any, templateName: string) => {
      const targetTemplate = AigcServiceConsts.STYLE_TEMPLATE_LIST.find(({ name }) => {
        return name === templateName
      })
      try {
        await officeHelper.insertOoXML(targetTemplate!.templateStr)
      } catch (error) {
        console.error('插入失败', error)
      }
      event.completed()
    }

    const showUpgradeDialog = (event: any) => {
      officeHelper.openPricingDialog(() => {
        event.completed()
      })
    }

    const showContactDialog = (event: any) => {
      officeHelper.openContactDialog(() => {
        event.completed()
      })
    }

    Office.actions.associate('insertShouRuTemplate', (event) => {
      insertStyleTemplate(event, '收入证明')
    })
    Office.actions.associate('insertFangJiaTemplate', (event) => {
      insertStyleTemplate(event, '放假通知')
    })
    Office.actions.associate('insertLiZhiTemplate', (event) => {
      insertStyleTemplate(event, '离职证明')
    })
    Office.actions.associate('insertZaiZhiTemplate', (event) => {
      insertStyleTemplate(event, '在职证明')
    })
    Office.actions.associate('showUpgradeDialog', showUpgradeDialog)
    Office.actions.associate('showContactDialog', showContactDialog)
  }

  private async checkSelectedText() {
    if (!userService.isLogin) {
      return
    }
    const selectedText = await officeHelper.getCurrentSelectedText()
    // 改变顶栏划词增强 Tab 的状态
    if (selectedText.trim()) {
      this.toggleHuaCiTabStatus(true)
    } else {
      this.toggleHuaCiTabStatus(false)
    }
  }

  private toggleHuaCiTabStatus(enable: boolean) {
    console.log('toggleHuaCiTabStatus', enable)
    const tabList: Office.Tab[] = [
      {
        id: 'TabGroup.HuaCi',
        controls: [
          {
            id: 'TabGroup.HuaCi.GaiXie',
            enabled: true,
          },
          {
            id: 'TabGroup.HuaCi.KuoXie',
            enabled: true,
          },
          {
            id: 'TabGroup.HuaCi.SuoXie',
            enabled: true,
          },
          {
            id: 'TabGroup.HuaCi.XuXie',
            enabled: true,
          },
        ],
      },
    ]
    Office.ribbon.requestUpdate({
      tabs: tabList.map((t) => {
        t.controls!.forEach((c) => {
          c.enabled = enable
        })
        return t
      }),
    })
  }
}

export const officeCommand = new OfficeCommand()
