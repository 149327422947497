import { uiController } from '@/controllers/uiController'
import { userService } from '@/services/userService'
import { envHelper } from './envHelper'
import { officeCommand } from './officeCommand'
import { commonUtils } from './commonUtils'

class OfficeHelper {
  private initialized = false

  public officeDownloadUrl = 'https://msdn.itellyou.cn'

  public init() {
    if (this.initialized) {
      return
    }

    Office.onReady((info) => {
      console.log('office onReady:', info)

      if (this.isWebEnvironment()) {
        return
      }

      if (!this.isDialog()) {
        if (!Office.context.requirements.isSetSupported('DialogApi')) {
          uiController.showLowVersionDialog = true
          return
        }
      }

      if (!this.isDialog() && Office.addin) {
        console.log('setStartupBehavior and init ')

        Office.addin.setStartupBehavior(Office.StartupBehavior.load)
        officeCommand.init()
      }
    })

    this.initialized = true
  }

  public isDialog() {
    return (Office.context as any).isDialog
  }

  public isWebEnvironment() {
    const platform = Office.context.host
    if (platform === undefined) {
      // 说明运行在web中，没有在word里运行
      console.log('运行在非office环境中.')
      return true
    }

    return false
  }

  public openBrowserTab(url: string): Promise<boolean> {
    return new Promise((resolve, _) => {
      Office.onReady(() => {
        if (!Office.context.requirements.isSetSupported('OpenBrowserWindowApi')) {
          console.log('不支持OpenBrowserWindowApi')
          resolve(false)
          return
        }

        Office.context.ui.openBrowserWindow(url)
        resolve(true)
      })
    })
  }

  public openLoginDialog() {
    console.log('openLoginDialog', `${envHelper.host}/#/addins/login`)

    Office.onReady(() => {
      if (!Office.context.requirements.isSetSupported('DialogApi')) {
        console.log('不支持DialogApi')
        uiController.showLowVersionDialog = true
        return
      }

      Office.context.ui.displayDialogAsync(
        `${envHelper.host}/#/addins/login`,

        {
          width: 50,
          height: 60,
        },
        ({ value: dialog }) => {
          console.log('...dialog:', dialog)

          dialog.addEventHandler(Office.EventType.DialogMessageReceived, (_arg) => {
            const arg = _arg as { message: string; origin: string | undefined }
            const res = JSON.parse(arg.message)
            if (res.loginSuccess) {
              userService.updateUserInfo()
              uiController.message.success('登录成功')
            }
            dialog.close()
          })
        }
      )
    })
  }

  public async openPricingDialog(callback?: () => void) {
    console.log('open pricing dialog', `${envHelper.webHost}/pricing`)

    const open = await this.openBrowserTab(`${envHelper.webHost}/pricing?fromExtension=1`)

    uiController.showUpgradeDialog = true

    if (!open) {
      commonUtils.copyToClipboard(`${envHelper.webHost}/pricing?fromExtension=1`)
      uiController.message.success('链接已经复制到粘贴板，请打开浏览器粘贴到地址栏')
    }

    callback?.()
  }

  public async openContactDialog(callback?: () => void) {
    console.log('open contact dialog', `${envHelper.webHost}/help`)

    const url = `${envHelper.webHost}/help`
    const open = await this.openBrowserTab(url)

    if (!open) {
      commonUtils.copyToClipboard(url)
      uiController.message.success('链接已经复制到粘贴板，请打开浏览器粘贴到地址栏')
    }
    callback?.()
  }

  public sendMessageToParent(data: { [key: string]: string | boolean }) {
    Office.onReady(() => {
      Office.context.ui.messageParent(JSON.stringify(data))
    })
  }

  public async insertOoXML(oOXML: string) {
    return this.insertDataToSelectedRange(oOXML, Office.CoercionType.Ooxml)
  }

  public async insertText(text: string) {
    return this.insertDataToSelectedRange(text, Office.CoercionType.Text)
  }

  public async insertHtml(htmlStr: string) {
    return this.insertDataToSelectedRange(htmlStr, Office.CoercionType.Html)
  }

  public getCurrentSelectedText(): Promise<string> {
    return new Promise((resolve, reject) => {
      Office.onReady(() => {
        Office.context.document.getSelectedDataAsync(
          Office.CoercionType.Text,
          ({ value, error }) => {
            if (!error) {
              resolve((value as string) || '')
            } else {
              reject(error)
            }
          }
        )
      })
    })
  }

  private insertDataToSelectedRange(data: string, coercionType: Office.CoercionType) {
    return new Promise<null>((resolve, reject) => {
      Office.onReady(() => {
        Office.context.document.setSelectedDataAsync(
          data,
          {
            coercionType,
          },
          (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              resolve(null)
            } else {
              reject(`插入失败：${asyncResult.error}` || '插入失败，请重试或联系客服')
            }
          }
        )
      })
    })
  }
}

export const officeHelper = new OfficeHelper()
