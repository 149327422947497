import { ElementType, Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import AmeSpin from './components/AmeSpin/AmeSpin'
import { PAGE_LINKS } from './helpers/commonConsts'

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<AmeSpin fullscreen={true} />}>
      <Component {...props} />
    </Suspense>
  )

const IntroPage = Loadable(lazy(() => import('./pages/IntroBox/IntroBox')))
const HomePage = Loadable(lazy(() => import('./pages/HomePage/HomePage')))
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage/LoginPage')))
const PersonalPage = Loadable(lazy(() => import('./pages/PersonalPage/PersonalPage')))
const FormPage = Loadable(lazy(() => import('./pages/FormPage/FormPage')))
const ResultPage = Loadable(lazy(() => import('./pages/ResultPage/ResultPage')))
const HelpPage = Loadable(lazy(() => import('./pages/HelpPage/HelpPage')))
const ChatPage = Loadable(lazy(() => import('./pages/ChatPage/ChatPage')))

type RouteObject = {
  path: string
  element: JSX.Element
}
const routes: RouteObject[] = [
  {
    path: PAGE_LINKS.intro,
    element: <IntroPage />,
  },

  {
    path: PAGE_LINKS.home,
    element: <HomePage />,
  },

  {
    path: PAGE_LINKS.login,
    element: <LoginPage />,
  },
  {
    path: PAGE_LINKS.personal,
    element: <PersonalPage />,
  },
  {
    path: PAGE_LINKS.form,
    element: <FormPage />,
  },
  { path: PAGE_LINKS.result, element: <ResultPage /> },
  { path: PAGE_LINKS.help, element: <HelpPage /> },
  { path: PAGE_LINKS.chat, element: <ChatPage /> },
]

export const Router = () => {
  return (
    <>
      <Switch>
        {routes.map((route) => {
          return (
            <Route key={route.path} path={route.path} exact>
              {route.element}
            </Route>
          )
        })}
      </Switch>
    </>
  )
}
