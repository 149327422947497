import { TagType } from '@/components/SendMessageBox/SendMessageBox'
import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

interface ChatResponseItem {
  /**
   * 内容
   */
  content: string
  /**
   * 消息时间
   */
  msgTime: number
  /**
   * 快捷请求类型
   */
  quickAction?: TagType
  /**
   * 角色类型
   */
  role: 'init' | 'user' | 'assistant'
  /**
   * 消息任务id
   */
  taskId?: number

  oldTaskId?: number
}

export interface ChatHistoryItem {
  chatId: string
  chatName: null
  taskData: TaskData
}

interface TaskData {
  inputContent: string
  msgTime: number
  quickAction: null
  resContent: string
  taskId: number
}

export interface TemplateItem {
  /**
   * 生成内容
   */
  content: string
  /**
   * 描述
   */
  description: string
  icon: string
  id: string
  /**
   * 消息时间
   */
  msgTime: number
  /**
   * 模板名称
   */
  name: string
  /**
   * 封面图
   */
  previewImg: string
  taskId: number
}

interface ResultDetail {
  createdAt: number
  deleted: boolean
  id: number
  /**
   * 输入内容
   */
  inputContent: string
  /**
   * 是否喜欢
   */
  likeStatus: number
  /**
   * AI 输出结果
   */
  resContent: string
  /**
   * 模板id
   */
  templateId: null
  updatedAt: number
}

export namespace chat {
  export function createChat(name?: string): Promise<
    ApiBaseResult & {
      data: {
        /**
         * 会话id
         */
        id: string
        /**
         * 会话名称
         */
        name?: string
        initMsg: string
      }
    }
  > {
    return post('/chat/create', {}, { name })
  }

  export function createChatTask(data: {
    text: string
    chatId: string
    quickAction?: string
    oldTaskId?: number
  }): Promise<ApiBaseResult & { taskId: number; chatTaskCount: number; oldTaskId?: number }> {
    return post('/chat/ai_write/create', {}, { ...data })
  }

  export function getMessageListByChatId(
    chatId: string
  ): Promise<ApiBaseResult & { dataList: ChatResponseItem[]; chatTaskCount: number }> {
    return get('/chat/msg_list', { chatId })
  }

  export function getChatHistory(
    offset: number,
    limit: number
  ): Promise<ApiBaseResult & { dataList: ChatHistoryItem[]; totalCount: number }> {
    return get('/chat/history_list', { offset, limit })
  }

  export function getTemplateHistory(
    offset: number,
    limit: number
  ): Promise<ApiBaseResult & { dataList: TemplateItem[]; totalCount: number }> {
    return get('/template/history_list', { offset, limit })
  }

  export function getTemplateResult(
    taskId: number
  ): Promise<ApiBaseResult & { data: ResultDetail; subDataList: Array<ResultDetail> }> {
    return get('/ai_write/task/detail', { taskId })
  }
}
