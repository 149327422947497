import freeVipNoCountBanner from '@/assets/dialog/banner__free-vip-no-count.png'
import versionImg from '@/assets/dialog/version.png'
import { uiController } from '@/controllers/uiController'
import { CONTACT_QR_CODE } from '@/helpers/commonConsts'
import { officeHelper } from '@/helpers/officeHelper'
import { Modal } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FC } from 'react'
import AmeButton from '../AmeButton/AmeButton'
import styles from './CommonDialogLayout.module.less'
import { commonUtils } from '@/helpers/commonUtils'
import { userService } from '@/services/userService'
import PayImg from '@/assets/dialog/pay.png'

interface CommonDialogLayoutProps {}

const CommonDialogLayout: FC<CommonDialogLayoutProps> = observer(
  (props: CommonDialogLayoutProps) => {
    const updateOffice = async () => {
      const open = await officeHelper.openBrowserTab(officeHelper.officeDownloadUrl)
      if (!open) {
        commonUtils.copyToClipboard(officeHelper.officeDownloadUrl)
        uiController.message.success('链接已经复制到粘贴板，打开浏览器并粘贴路径即可')
      }

      uiController.showLowVersionDialog = false
    }

    return (
      <div className={classNames(styles.commonDialogLayout)}>
        {/* 免费用户字数不足弹窗 */}
        <Modal
          className={classNames(styles.freeVipNoCountModal)}
          open={uiController.showFreeVipNoCountDialog}
          title='剩余字数不足'
          footer={null}
          getContainer={false}
          onCancel={() => {
            uiController.showFreeVipNoCountDialog = false
          }}
        >
          <img className={classNames(styles.banner)} src={freeVipNoCountBanner} />
          <p className={classNames(styles.desc)}>您的免费额度已用完，开通 VIP 畅享更多 AI 能力</p>
          <AmeButton
            className={classNames(styles.confirmBtn)}
            type='primary'
            onClick={() => {
              officeHelper.openPricingDialog()
              uiController.showFreeVipNoCountDialog = false
            }}
          >
            开通
          </AmeButton>
        </Modal>
        {/* 付费用户剩余字数不足弹窗 */}
        <Modal
          className={classNames(styles.contactModal, styles.vipNoCountModal)}
          open={uiController.showVipNoCountDialog}
          title='本月字数已用完'
          footer={null}
          getContainer={false}
          onCancel={() => {
            uiController.showVipNoCountDialog = false
          }}
        >
          <img className={classNames(styles.qrCode)} src={CONTACT_QR_CODE} />
          <p className={classNames(styles.desc)}>扫码添加您的专属客服，为您添加更多字数</p>
        </Modal>
        {/* 联系客服 */}
        <Modal
          className={classNames(styles.contactModal, styles.vipNoCountModal)}
          open={uiController.showContactDialog}
          title='联系客服'
          footer={null}
          getContainer={false}
          onCancel={() => {
            uiController.showContactDialog = false
          }}
        >
          <img className={classNames(styles.qrCode)} src={CONTACT_QR_CODE} />
          <p className={classNames(styles.desc)}>扫码添加您的专属客服，为您解决产品使用问题</p>
        </Modal>
        {/* 字数充值 */}
        <Modal
          className={classNames(styles.contactModal, styles.vipNoCountModal)}
          open={uiController.showAddCountDialog}
          title='联系客服'
          footer={null}
          getContainer={false}
          onCancel={() => {
            uiController.showAddCountDialog = false
          }}
        >
          <img className={classNames(styles.qrCode)} src={CONTACT_QR_CODE} />
          <p className={classNames(styles.desc)}>扫码添加您的专属客服，为您添加更多字数</p>
        </Modal>

        {/* 版本过低提示 */}
        <Modal
          className={classNames(styles.versionModal)}
          open={uiController.showLowVersionDialog}
          footer={null}
          getContainer={false}
          width={296}
          closeIcon={<></>}
          maskClosable={false}
        >
          <p className={classNames(styles.title)}>当前office版本过低</p>
          <img className={classNames(styles.versionImg)} src={versionImg} />
          <p className={classNames(styles.desc)}>
            当前office版本过低，无法支持addin加载项正常功能使用
          </p>

          <AmeButton className={styles.btn} type='primary' onClick={() => updateOffice()}>
            去升级
          </AmeButton>
        </Modal>

        {/* 续费或者升级的提示 */}
        <Modal
          className={classNames(styles.upgradeModal)}
          open={uiController.showUpgradeDialog}
          onCancel={() => (uiController.showUpgradeDialog = false)}
          footer={null}
          getContainer={false}
          maskClosable={false}
          width={296}
        >
          <p className={classNames(styles.title)}>支付中...</p>
          <img className={classNames(styles.versionImg)} src={PayImg} />
          <p className={classNames(styles.desc)}>如果您已完成支付，请点击已支付按钮</p>

          <AmeButton
            className={styles.btn}
            type='primary'
            onClick={() => {
              userService.updateUserInfo()
              uiController.showUpgradeDialog = false
            }}
          >
            已支付
          </AmeButton>

          <p
            className={classNames(styles.cancel)}
            onClick={() => (uiController.showUpgradeDialog = false)}
          >
            取消
          </p>
        </Modal>
      </div>
    )
  }
)

export default CommonDialogLayout
