import { uiController } from '@/controllers/uiController'
import { api } from '@/helpers/api'
import { aigc } from '@/helpers/api/aigc'
import { ERROR_CODE } from '@/helpers/commonConsts'
import { envHelper } from '@/helpers/envHelper'
import { officeHelper } from '@/helpers/officeHelper'
import { storageHelper } from '@/helpers/storageHelper'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { computed, observable } from 'mobx'
import { userService } from '../userService'
import { AigcServiceConsts } from './consts'
import { AigcServiceTypes } from './serviceTypes'

type StreamTaskId = string

class AigcService {
  @observable aiTemplateList: Array<AigcServiceTypes.AITemplateData> = []
  @observable styleTemplateList: Array<AigcServiceTypes.StyleTemplateData> =
    AigcServiceConsts.STYLE_TEMPLATE_LIST

  // 一个 task 里会包含多个 stream task
  @observable taskMap = new Map<
    string,
    Array<{
      streamTaskId: StreamTaskId
      eventSource?: EventSourcePolyfill
      resultText: string
      finished: boolean
      resultTextHtmlStr: string
      feedback?: 'like' | 'dislike'
      errorMsg?: string
    }>
  >()

  @computed get allTemplateList(): Array<AigcServiceTypes.TemplateBaseData> {
    return this.aiTemplateList.concat(this.styleTemplateList as any)
  }

  public init() {
    this.loadCacheData()
    this.updateAITemplateList()
  }

  public async createSelectEnhanceTask({
    mainTaskId,
    type,
    reCreate = false,
  }: {
    mainTaskId: string
    type: AigcServiceTypes.SelectEnhanceType
    reCreate: boolean
  }) {
    if (!userService.hasRemainCount) {
      return Promise.reject(ERROR_CODE.NO_COUNT)
    }
    if (!reCreate) {
      this.taskMap.set(mainTaskId, [])
    }
    const selectedText = (await officeHelper.getCurrentSelectedText()).trim()
    if (!selectedText) {
      return Promise.reject('请选中内容后操作')
    }
    const { taskId: streamTaskId } = await api.aigc.createQuickActionTask({
      text: selectedText,
      quickAction: type,
    })
    const eventSource = this.initTaskStream(mainTaskId, streamTaskId)
    return { eventSource }
  }

  public async createAITemplateTask({
    mainTaskId,
    templateId,
    reCreate = false,
  }: {
    mainTaskId: string
    templateId: string
    reCreate: boolean
  }) {
    if (!userService.hasRemainCount) {
      return Promise.reject(ERROR_CODE.NO_COUNT)
    }
    if (!reCreate) {
      this.taskMap.set(mainTaskId, [])
    }
    const inputData = uiController.getInputDataFromCache(templateId)
    if (!inputData) {
      return Promise.reject('内部错误，请稍后重试或联系客服(as1)')
    }
    let oldTaskId = ''
    if (reCreate) {
      // 取第一条数据的 streamTaskId 作为 oldTaskId
      const taskArr = this.taskMap.get(mainTaskId) ?? []
      oldTaskId = taskArr[0].streamTaskId
    }
    const { taskId: streamTaskId } = await api.aigc.createTemplateStreamTask({
      templateId,
      inputData,
      oldTaskId,
    })
    const eventSource = this.initTaskStream(mainTaskId, streamTaskId)
    return { eventSource }
  }

  private initTaskStream(mainTaskId: string, streamTaskId: string): EventSourcePolyfill {
    const eventSource = new EventSourcePolyfill(
      `${envHelper.apiHost}${envHelper.apiBasePath}/ai_write/stream?task_id=${streamTaskId}`
    )
    this.initTaskStreamItem({ mainTaskId: mainTaskId, streamTaskId, eventSource })

    eventSource.addEventListener('ai_resp_stream', (e) => {
      const { data } = e as any
      const {
        delta: { content },
      } = JSON.parse(data)
      this.getTaskStreamItem(mainTaskId, streamTaskId)!.resultText += content
    })
    eventSource.addEventListener('ai_end_stream', async (e) => {
      eventSource.close()
      userService.updateUserInfo()
      this.getTaskStreamItem(mainTaskId, streamTaskId)!.finished = true
    })
    eventSource.onerror = (e: any) => {
      // 正常情况下不会出现 error 的情况
      console.error('ai_stream_error', e)
      eventSource.close()
      this.getTaskStreamItem(mainTaskId, streamTaskId)!.finished = true
      this.getTaskStreamItem(mainTaskId, streamTaskId)!.errorMsg =
        e.data || '内部逻辑错误，请重试或联系客服'
      userService.updateUserInfo()
    }
    return eventSource
  }

  public stopTaskStream(mainTaskId: string) {
    this.taskMap.get(mainTaskId)?.forEach((s) => {
      s.finished = true
      s.eventSource?.close()
    })
    userService.updateUserInfo()
  }

  public async postFeedback({
    mainTaskId,
    streamTaskId,
    feedback,
  }: {
    mainTaskId: string
    streamTaskId: string
    feedback: 'like' | 'dislike'
  }) {
    await aigc.postFeedback(streamTaskId, feedback)
    this.getTaskStreamItem(mainTaskId, streamTaskId)!.feedback = feedback
  }

  public setTaskStreamResultTextHtmlStr({
    mainTaskId,
    streamTaskId,
    resultTextHtmlStr,
  }: {
    mainTaskId: string
    streamTaskId: string
    resultTextHtmlStr: string
  }) {
    this.getTaskStreamItem(mainTaskId, streamTaskId)!.resultTextHtmlStr = resultTextHtmlStr
  }

  public async initHistoryData(taskId: number, mainTaskId: string) {
    const { subDataList = [] } = await api.chat.getTemplateResult(taskId)
    this.taskMap.set(mainTaskId, [])

    subDataList.forEach(({ id, resContent }) => {
      this.taskMap.get(mainTaskId)?.push({
        streamTaskId: id + '',
        resultText: resContent,
        finished: true,
        resultTextHtmlStr: '',
      })
    })
  }

  private initTaskStreamItem({
    mainTaskId,
    streamTaskId,
    eventSource,
  }: {
    mainTaskId: string
    streamTaskId: string
    eventSource: EventSourcePolyfill
  }) {
    this.taskMap.get(mainTaskId)?.push({
      streamTaskId: streamTaskId,
      finished: false,
      eventSource,
      resultText: '',
      resultTextHtmlStr: '',
    })
  }

  private getTaskStreamItem(mainTaskId: string, streamTaskId: string) {
    return this.taskMap.get(mainTaskId)?.find((r) => r.streamTaskId === streamTaskId)
  }

  private loadCacheData() {
    const { aiTemplateList } = storageHelper.get(['aiTemplateList'])
    this.aiTemplateList = aiTemplateList || []
  }

  private updateAITemplateList() {
    api.aigc
      .getAITemplateList()
      .then(({ dataList }) => {
        const aiTemplateList = dataList.map((d, idx) => {
          return {
            ...d,
            type: 'ai',
          }
        })
        this.aiTemplateList = JSON.parse(JSON.stringify(aiTemplateList))
        storageHelper.set({ aiTemplateList })
      })
      .catch(() => {
        // do nothing
      })
  }
}

export const aigcService = new AigcService()
