import { ConfigProvider, message } from 'antd'
import 'antd/dist/antd.min.css'
import hasOwn from 'object.hasown'
import { useLayoutEffect } from 'react'

import {
  // BrowserRouter,
  HashRouter,
  RouterChildContext,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'

import './App.less'
import { Router } from './Router'
import CommonDialogLayout from './components/CommonDialogLayout/CommonDialogLayout'
import { uiController } from './controllers/uiController'
import { commonUtils } from './helpers/commonUtils'
import { officeHelper } from './helpers/officeHelper'
import { aigcService } from './services/aigcService'
import { routerService } from './services/routerService/routerService'
import { userService } from './services/userService'

if (!Object.hasOwn) {
  Object.hasOwn = hasOwn
}

if (!window.crypto) {
  window.crypto = window.msCrypto
}

if (!String.prototype.replaceAll) {
  // eslint-disable-next-line
  String.prototype.replaceAll = function (str: any, newStr: any) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr)
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr)
  }
}

const RouterLayout = () => {
  // 只要使用了 useNavigate ，url 变化都会导致此组件重新渲染
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = useParams()
  routerService.history = useHistory() as RouterChildContext['router']['history']

  routerService.location = useLocation() as RouterChildContext['router']['route']['location']
  useLayoutEffect(() => {
    routerService.setParams({ ...params, ...commonUtils.getUrlParams })
  })
  return null
}

export default function App() {
  const [messageApi, contextHolder] = message.useMessage()
  uiController.message = messageApi
  return (
    <ConfigProvider autoInsertSpaceInButton={false}>
      <HashRouter basename='/addins'>
        <CommonDialogLayout />
        {contextHolder}
        <RouterLayout />
        <Router />
      </HashRouter>
    </ConfigProvider>
  )
}

uiController.init()
userService.init()
aigcService.init()
officeHelper.init()
