import { AigcServiceTypes } from '@/services/aigcService/serviceTypes'
import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace aigc {
  export function getAITemplateList(): Promise<ApiBaseResult & {
    dataList: Array<AigcServiceTypes.AITemplateData>
  }> {
    return get('/ai_write/template/list')
  }

  export function createTemplateStreamTask(data: PostData.AiTemplateOptions): Promise<{ taskId: string }> {
    return post('/ai_write/create', {}, { ...data })
  }

  export function createQuickActionTask(data: PostData.QuickActionOptions): Promise<{ taskId: string }> {
    return post('/ai_write/create', {}, { ...data })
  }

  export function postFeedback(taskId: string, feedback: 'like' | 'dislike'): Promise<ApiBaseResult> {
    return post('/task_like_status', {}, {
      taskId,
      status: feedback === 'like' ? 1 : 2
    })
  }
}