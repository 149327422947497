import { Spin } from "antd"
import classNames from "classnames"
import { observer } from "mobx-react"
import { FC } from "react"
import styles from "./AmeSpin.module.less"

interface AmeSpinProps {
  className?: string
  fullscreen?: boolean
}

const AmeSpin: FC<AmeSpinProps> = observer((props: AmeSpinProps) => {
  const { fullscreen, className } = props
  return (
    <Spin
      className={classNames(styles.ameSpin, className, {
        [styles.fullscreen]: fullscreen,
      })}
    />
  )
})

export default AmeSpin
