import { PAGE_LINKS } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { observable, set } from 'mobx'

import { RouterChildContext } from 'react-router-dom'
import { RouterServiceTypes as Types } from './serviceTypes'

export class RouterService {
  public history!: RouterChildContext['router']['history']
  public location!: RouterChildContext['router']['route']['location']

  @observable params: {
    [key: string]: string
  } = {}

  public setParams(params: Record<string, string>) {
    set(this.params, params)
  }

  public back() {
    this.history.go(-1)
  }

  public navigateTo<P extends Types.PageType>(pageName: P, payload?: Types.PageParams[P]) {
    const url = commonUtils.genUrl(PAGE_LINKS[pageName], payload || {})
    console.log('url:', url)
    this.history.push(url)
  }
}

export const routerService = new RouterService()
