import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace user {
  // 获取登录二维码
  export function getLoginQrCode(params: {
    utm?: string
    kword?: string
    refererChannel?: string
    refererChannelDetail?: string
  }): Promise<ApiBaseResult & {
    url: string
    sceneId: string
    ticket: string
  }> {
    return get('/auth/register_qr', { ...params, registerChannel: params.utm })
  }

  // 检查登录状态
  export function checkLoginStatus(sceneId: string): Promise<ApiBaseResult & { status: 'no_scan' | 'success' }> {
    return get('/auth/register_qr/check', { sceneId })
  }

  export function logout() {
    return get('/user/logout')
  }

  export function getUserInfo(): Promise<ApiBaseResult & { userData: ServerData.UserData }> {
    return get('/user/me')
  }

  // 直接登录
  export function directLoginForReview(): Promise<ApiBaseResult> {
    return post('/quick_login', {}, {})
  }

}

