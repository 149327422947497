import { uiController } from '@/controllers/uiController'
import { api } from '@/helpers/api'
import { PAGE_LINKS } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import { action, computed, observable } from 'mobx'
import { routerService } from '../routerService'

class UserService {
  @observable userData: ServerData.UserData | null = null

  isIE: boolean = false

  @computed get isLogin() {
    // console.log('this.userData?.versionData:', this.userData?.versionData)
    return !!this.userData?.versionData
  }

  @computed get isVip() {
    if (!this.isLogin) {
      return false
    }
    return this.userData?.versionData?.version !== 'free'
  }

  @computed get totalWriteCount() {
    if (!this.isLogin) {
      return 0
    }
    const { freeAiWriteCount, proAiWriteCount } = this.userData?.versionData!
    if (this.isVip) {
      return proAiWriteCount
    }
    return freeAiWriteCount
  }

  @computed get hasRemainCount() {
    return this.totalWriteCount > 0
  }

  public init() {
    this.loadCacheData()
    this.updateUserInfo()
    if (document.documentMode) {
      this.isIE = true
    } else {
      this.isIE = false
    }
  }

  @action public setUserData(userData: ServerData.UserData) {
    this.userData = userData
  }

  public async updateUserInfo() {
    try {
      const { userData } = await api.user.getUserInfo()
      console.log('get user info: ', userData)
      this.setUserData(userData)
      console.log('after set:', this.userData)
      storageHelper.set({ userData })
    } catch (error) {
      console.log('update user error:', error)
      if (routerService.location.pathname === PAGE_LINKS['login']) {
        return
      }
      if (routerService.location.pathname === PAGE_LINKS['help']) {
        return
      }
      this.userData = null
      storageHelper.set({ userData: null })
      routerService.navigateTo('intro')
    }
  }

  public async logout() {
    try {
      await api.user.logout()
      this.userData = null
      storageHelper.set({ userData: null })
      uiController.message.success('退出成功')
      setTimeout(() => {
        // routerService.navigateTo('home')
        routerService.navigateTo('intro')
      }, 1)
    } catch (error) {
      uiController.message.error(`操作失败：${commonUtils.formatErrorMsg(error)}`)
    }
  }

  private loadCacheData() {
    const { userData } = storageHelper.get(['userData'])
    this.userData = userData
  }
}

export const userService = new UserService()
